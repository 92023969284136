.footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333;
    color: #fff;
    padding: 20px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  
  .footer-link {
    color: #fff;
    text-decoration: none;
    margin-right: 20px;
  }
  
  .footer-text {
    margin-left: 20px;
  }
  