.construction-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
  }
  
  .construction-title {
    font-size: 32px;
    color: #333;
  }
  
  .construction-text {
    font-size: 24px;
    color: #666;
  }
  